import { Container, Row, Col, Form } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { useState } from "react";
import "./reset-password.css";
import ButtonPrimary from "../../components/buttons/buttons";
import { BaseAPI } from "../../providers/base-api";
import { useNavigate, useParams } from "react-router-dom";
import { ResetFormData } from "../../models/auth";

interface ResetFieldErrors {
  password?: string;
  confirmPassword?: string;
  form?: string;
}

const ResetForm = (props: any) => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [successmsg, setSuccessMessage] = useState(false);
  const [inProgress,setInProgress]=useState(false) 
  const [errors, setErrors] = useState<ResetFormData>({
    confirmPassword: "",
    password: "",
  });
  const [form, setForm] = useState<ResetFormData>({
    confirmPassword: "",
    password: "",
  });

  const [hasPasswordMismatch, setHasPasswordMismatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (fieldName, value) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: value }));
    if (!!errors[fieldName])
      setErrors({
        ...errors,
        [fieldName]: null,
      });

    if (["password", "confirmPassword"].includes(fieldName)) {
      const password = fieldName === "password" ? value : form.password;
      const confirmPassword =
        fieldName === "confirmPassword" ? value : form.confirmPassword;
      if (
        password?.length > 0 &&
        confirmPassword?.length > 0 &&
        password !== confirmPassword
      ) {
        setHasPasswordMismatch(true);
      } else {
        setHasPasswordMismatch(false);
      }
    }
  };
  const validateForm = () => {
    const { password, confirmPassword } = form;
    const newErrors: ResetFieldErrors = {};
    if (!password || password === "")
      newErrors.password = "This field is required";
    if (!confirmPassword || confirmPassword === "")
      newErrors.confirmPassword = "This field is required";

    return newErrors;
  };

  const params = useParams();
  const idParam = params?.id;
 
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setInProgress(true)
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    let payload = {
        resetPasswordToken: idParam,
      password: form?.password,
    };
    try {
      await BaseAPI.resetPassword(payload, (result: any) => {
        setErrorMessage(result?.response?.data?.errors?.username);
        if (result?.data?.statusCode === 202) {
          setSuccessMessage(true);
          setTimeout(() => {
           navigate("/#login")
          }, 1000);
        } else {
          setValidated(true)
          setSuccessMessage(false);
          setErrors({ form: 'Invalid Email ' })
        }
      });
    } catch (error) {
      setErrorMessage(error?.response?.data?.errors?.username);
      setInProgress(false)
    }
    setValidated(true);
  };
  return (
    <div className="bg-cl-reset-password">
      <div className="banner-div-reset-password">
        <Row>
          <Col>
            <Container className="reset" style={{ marginTop: "128px" }}>
              <div className="main">
                <div className="row" style={{ marginTop: "-3rem" }}>
                  <div className="col-1"></div>
                  <div
                    className="col-3 content"
                    style={{
                      marginLeft: "-2.5rem",
                      marginRight: "4rem",
                      backgroundColor: "white",
                      height: "0px",
                    }}
                  >
                    <h1 className="maintitle">Reset Password</h1>
                  </div>
                  <div className="col-7 form">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3 signup-row"></Row>

                      <Row>
                        <Col>
                          <Form.Control
                            type="password"
                            placeholder="New Password *"
                            required
                            value={form.password}
                            disabled={inProgress}
                            name="password"
                            onChange={(e: any) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Control
                          className="password-padding"
                            type="password"
                            placeholder="Confrim Password *"
                            required
                            value={form.confirmPassword}
                            disabled={inProgress}
                            name="confirmPassword"
                            onChange={(e: any) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Row>
                        {hasPasswordMismatch ? (
                          <span className="err-msg">
                            Password and confirm password must match
                          </span>
                        ) : null}
                      </Row>
                      <Row>
                        {errorMessage && !hasPasswordMismatch ? (
                          <span className="err-msg">{errorMessage}</span>
                        ) : null}
                      </Row>
                      <Row >
                   
                  {successmsg &&
                     <div className="success-msg">
                       <span >Your IFN account password has been successfully reset. </span>
                     </div>
                   }
                         </Row>
                         
                      <div className="row">
                        <div className="col sign-up-loginbtn-mrg"></div>
                        <div className="col">
                          <ButtonPrimary className="ssign" type="submit" disabled={inProgress}>
                            {" "}
                            Save &nbsp; 
                            <ArrowRight className="arrow" size={28} />{" "}
                          </ButtonPrimary>
                        </div>
                      </div>
                    
                    </Form>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ResetForm;
