import "./titlecard.css";

export default function TitleCard({ descriptionClass='', description = '', description2 = '', title=''}) {

  const additionalClass = descriptionClass || '';
  return (
    <>
      <h1 className="title-head">{title}</h1>
      {description && 
        <p className={`title-body ${additionalClass}`}>{description}</p>
      }
      {description2 &&
        <p className={`title-description2 ${additionalClass}`}>{description2}</p>
      }
    </>
  );
}
