import { useState } from "react";
import ls from 'localstorage-slim';
import { LocalStorageConfig } from "localstorage-slim/dist/types";

export const useLocalStorage = (keyName, defaultValue, options?:LocalStorageConfig) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if(options?.encrypt && !options.secret) {
        options.secret = process.env.REACT_APP_SECRET;
        options.decrypt = true
      }
      ls.config.encrypt = true;
      ls.config.secret = options.secret;

      const getOptions = { decrypt: true}
      const value = ls.get(keyName, getOptions);
      return value;

    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      ls.config.encrypt = true;
      ls.config.secret = options.secret;
      ls.set(keyName, newValue, options)
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
