import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";

import img1 from "../../assets/images/image3.png";
import img2 from "../../assets/images/image1.png";
import img3 from "../../assets/images/image2.png";
import ButtonPrimary from "../../components/buttons/buttons";
import "./home.css"
import { To, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BaseAPI } from "../../providers/base-api";
import { CONTENT_TYPES } from "../../providers/models/home-cards";
import Cookies from "js-cookie";

import {
  ThemeContainer,
  ButtonColor,
  HomeBorderBox,
  CardHomeClr,
  CardHomeTextParagraph,
  CardHomeText,
  PopupContinue,
} from "../../components/styles/ThemeSwitching.styled";
import FeatureFund from "../eligibleprograms/featureFund";



const HomePage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleClick = function (linkTo: To) {
    navigate(linkTo)
  }
  const [content, setContent] = useState([])
  const [mainTitle, setMainTitle] = useState('')
  const [description, setDescription] = useState()

  const getContent = async () => {
    try {
      await BaseAPI.getContent(CONTENT_TYPES.HOME_CARDS, (result: any) => {
        if (result.status === 200) {
          setContent(result.data.data.items);
          setMainTitle(result.data.mainTitle || 'Matching Community Priorities with Federal Infrastructure Funding')
          let defaultDescription = 'Better understand if your infrastructure project might be eligible and ready for federal funding'
          if (Number(process.env.REACT_APP_TENANT) === 2) {
            defaultDescription = 'Explore how federal climate and infrastructure funding can power municipalities in Puerto Rico'
          }
          setDescription(result.data.description || defaultDescription)
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getContent();
    const popupShown = Cookies.get("ifn-span-popup-shown");
    if (popupShown !== "true") {
      setShowModal(true);
    }
  }, []);

  const [isCircleImgVisible, setisCircleImgVisible] = useState(true);

  useEffect(() => {

    if (Number(process.env.REACT_APP_TENANT) === 1) {
      setisCircleImgVisible(true);

    }
    else {
      setisCircleImgVisible(false);
    }

  }, []);

  const handlePopupClose = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);

    Cookies.set("ifn-span-popup-shown", "true", { expires: expirationDate });
    setShowModal(false);
  };
  return (
    <>
      <ThemeContainer>

        <div>

          <div>

            <div className="banner-div">
              <Row>
                <Col xs={7} md={7} className="title-align">
                  <HomeBorderBox>

                    <div>
                      <h3 className="tit-hd">{mainTitle}</h3>
                    </div>
                    <div>
                      <p className="tit-p">{description}</p>
                    </div>
                    <div className="test">

                      <ButtonColor className="btn-gt" onClick={() => handleClick("/projects/new")}> GET STARTED <ArrowRight className="arrow arrow-align" size={28} />  </ButtonColor>
                    </div>


                    <div>

                    </div>
                  </HomeBorderBox>
                </Col>
                <Col xs={5} md={5} className={isCircleImgVisible ? "col-visible" : "col-hidden"} >
                  <Row className="img-ht">
                    <Col><img className="banner-one img-design" src={img1} /></Col>
                    <Col><img className="banner-two img-design" src={img2} /></Col>
                    <Col><img className="banner-three img-design" src={img3} /></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <div className="ht-len">
            <div className="d-flex">
              <div className="container ">
                <div className="card-len card-container" >
                  {content && content.map((data, index) => {
                    return (
                      <Col className="card-col card-content" key={`cardc-${index}`}>
                        <Card key={`card-${index}`} className={`card-boder-tenant${Number(process.env.REACT_APP_TENANT) === 1 ? "1" : "2"}`}>
                          <Card.Body className="card-shadow">
                            <CardHomeText >{data.title}</CardHomeText>
                            <CardHomeTextParagraph>{data.display_title}</CardHomeTextParagraph>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>


      </ThemeContainer>
      <FeatureFund></FeatureFund>
    </>


  )
}
export default HomePage