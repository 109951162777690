
export function sortData(data: any[], sortByField: string, isAscending: boolean) {
    function compare(a: { [x: string]: number; }, b: { [x: string]: number; }) {
        if (a[sortByField] < b[sortByField]) {
            return isAscending ? -1 : 1;
        }
        if (a[sortByField] > b[sortByField]) {
            return isAscending ? 1 : -1;
        }
        return 0;
    }

    return data.sort(compare);
}
