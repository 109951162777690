import { useEffect, useState } from "react";
import LoginPage from "../../pages/login/login";
import SignUpPage from "../../pages/sign-up/sign-up";
import ModelWindow from "./ModelWindow";

function SignupOrLogin({ modalType, setModalType }) {
  const [internalModalType, setInternalModalType] = useState(modalType);

  const handleModelPopup = (event, name: string) => {
    event.preventDefault();
    // setShowState(true);
    setInternalModalType(name);
  };
  const onLoginSuccessful = () => {
    closeLoginPopup();
  };
  const closeLoginPopup = () => {
    setInternalModalType("");
    setModalType("");
    // if (modalType === "login" && showState) {
    //     // setShowState(false);
    // }
  };

  const changeToLoginModel = async (value) => {
    setInternalModalType(value)
  };

  useEffect(() => {
    setInternalModalType(modalType);
  }, [modalType]);
  // console.log('internalModalType',internalModalType)

  return (
    <>
      <ModelWindow
        show={internalModalType?.length > 0}
        onHide={() => closeLoginPopup()}
        body={
          internalModalType === "login" ? (
            <LoginPage
              handleModelPopup={handleModelPopup}
              onLoginSuccessful={onLoginSuccessful}
              redirectTo={undefined}
            />
          ) : (
            <SignUpPage
              handleModelPopup={handleModelPopup}
              changeToLoginModel={changeToLoginModel}
            />
          )
        }
        contentClassName={
          internalModalType === "login" ? "contentClassName" : "dialogSignUp"
        }
        dialogClassName="dialogClassName"
      />
    </>
  );
}

export default SignupOrLogin;
