import axios from "axios";
import { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getDefaultLocalStorageOptions } from "../utils/common-utils";
import { useLocalStorage } from "./useLocalStorage";


interface AuthContextType {
  user: any;
  login: (data:any, redirectTo?: string) => void;
  logout: (redirectTo?: string) => void;
   loginPopupVisible: boolean;
   setLoginPopupVisible: (visible: boolean) => void;
  loginOrSignupModalType : string;
  setLoginOrSignupModalType: (modalType: string) => void;
  targetPath: string;
  setTargetPath: (path: string) => void;
}
// const defaultContext: AuthContextType = undefined
const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthProvider = ({ children }) => {
  const appEnv = process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV : ''
  const lsKey = `ifnfe-${appEnv}-u`
  const options = getDefaultLocalStorageOptions(true);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const [user, setUser] = useLocalStorage(lsKey, null, options);
  const [loginOrSignupModalType, setLoginOrSignupModalType] = useState('');
  const [targetPath, setTargetPath] = useState<string>(undefined);
  const navigate = useNavigate();
  
  const value = useMemo(
    () => {
      
        const login = async (data: any, redirectTo?: string) => {
          setUser(data);
          axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
          if (redirectTo) {
            navigate(redirectTo, { replace: true });
          }
        };
      
        const logout = (redirectTo?: string) => {
          setUser(null);
          delete axios.defaults.headers.common['Authorization'];

          if (redirectTo) {
            navigate("/", { replace: true });
          }
        };

      return {
      user,
      login,
      logout,
      loginOrSignupModalType,
      loginPopupVisible,
      setLoginPopupVisible,
      setLoginOrSignupModalType,
      targetPath,
      setTargetPath
    }},
    
    [user, loginOrSignupModalType, targetPath, setUser, navigate, setLoginOrSignupModalType,loginPopupVisible]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};