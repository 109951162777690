import React, { useEffect, useState } from 'react';
import { TRANSLATE_OPTIONS, setCookie } from '../../utils/googleTranslate';
import './translate.css';

interface TranslateProps {
  isMobile?: boolean;
  width?: number;
  height?: number;
  onChangeValue?: (value: boolean) => void;
  rtl: boolean;
  setLanguage: (language: string) => void;
}

const Translate: React.FC<TranslateProps> = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('Spanish');

  useEffect(() => {
    const storedLang = localStorage.getItem('selectedLanguage');
    if (storedLang) {
      const langOption = TRANSLATE_OPTIONS.find((option) => option.value === storedLang);
      setSelectedLanguage(langOption ? langOption.label : 'Spanish');
      props.setLanguage(langOption ? langOption.label : 'Spanish');
    }
  }, []);

  const handleLanguageToggle = () => {
    const newLanguage = selectedLanguage === 'Spanish' ? 'English' : 'Spanish';
    const langOption = TRANSLATE_OPTIONS.find((option) => option.label === newLanguage);
    if (langOption) {
      setSelectedLanguage(langOption.label);
      localStorage.setItem('selectedLanguage', langOption.value);
      props.setLanguage(langOption.label);
      setCookie('googtrans', langOption.value === 'es' ? `/en/${langOption.value}` : `/es/${langOption.value}`); 
      const languageButton = document.querySelector('.goog-te-combo') as HTMLSelectElement;
      if (languageButton) {
        languageButton.value = langOption.value;
        const changeEvent = new MouseEvent('change', { bubbles: true, cancelable: true, view: window });
        languageButton.dispatchEvent(changeEvent);
      }
    }
  };

  const selectedLanguageLabel = selectedLanguage === 'Spanish' ? 'English' : 'Español';

  return (
    <div className='notranslate' translate="no" onClick={handleLanguageToggle} style={{ cursor: 'pointer' }}>
      <p className='language'>{selectedLanguageLabel}</p>
    </div>
  );
};

Translate.defaultProps = {
  isMobile: false,
  width: 14,
  height: 14,
  onChangeValue: () => false,
};

export default Translate;
