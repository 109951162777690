import { PieChart, Pie, Cell, Label } from 'recharts';

export default function DoughnutChartWithCenterText(props: { options: {label: string, metric: number, height: number, width: number, cutout?: number}}) {

    const options = props.options
    const cutout = options.cutout || 80;
    const outerRadius = options.width/2
    const innerRadius = outerRadius * cutout / 100
    const getColorByScore = (metric:number) => {
        if(typeof metric === 'undefined' || Number.isNaN(metric) || metric > 100 ) {
            return '#F9F8F8';

        } else {        
            const colorTable = [
                {scoreUpto: 24, color: "#E21C3D"},
                {scoreUpto: 50, color: "#FE8318"},
                {scoreUpto: 75, color: "#F9C642"},
                {scoreUpto: 100, color: "#48A463"},
            ]
            const colorItem = colorTable.find(colorItem => colorItem.scoreUpto >= metric)
            return colorItem.color;
        }
    }
    const colorForScore = getColorByScore(options.metric);
    const data = [
        {name: 'Secondary', metric: (100-options.metric),color: "#F8F8F8"},
        {name: 'Primary', metric: options.metric, color: colorForScore},
      ];

    return (
        <div>
        <PieChart width={options.width} height={options.height}>
            <Pie data={data} 
            dataKey="metric" 
            fill="green" 
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={-270}
           
            >
                <Label
                    value={`${options.metric}%`} position="centerBottom" className='label-top'/>
                <Label
                    value={options.label} position="centerTop" offset={100}  className='label-text'/>
                {data.map((entry, index) => <Cell key={index} fill={entry.color} fillOpacity={0.5}/>)}                            
            </Pie>
        </PieChart>
      </div>
    );
}
