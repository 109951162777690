import { Form } from 'react-bootstrap';

export default function SelectInput(props: any) {
    const { inputTitle, handleSelect, selectOptions,propsLabel, propsValue, ...restProps } = props;

    const handleOptions = (Options: any) => {
        let options: any = [];
        if (Options?.length) {
            Options?.map((item: any, index: any) => {
                options.push(<option key={index} value={item[propsValue]}>{item[propsLabel]}</option>)
            });
        }
        return options;
    };

    const onHandleSelect = (e: any) => {
        if(props.handleSelect) {
            return props.handleSelect(e.target.value);
        }
    }

    return (
        <Form.Select aria-label="Default select example" onClick={onHandleSelect} {...restProps}>
            <option value={''}>{inputTitle}</option>
            {handleOptions(selectOptions ? selectOptions : [])}
        </Form.Select>
    )
}

