import { Navigate, useLocation} from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const ProtectedRoute = ( {children}) => {
  const { user } = useAuth();

  let location = useLocation();

  if (!user) {
    // setTargetPath(location.pathname);
    // setLoginOrSignupModalType('');
    // setLoginOrSignupModalType('login')
    
    // user is not authenticated
    
    return <Navigate to="/#login" state={{ from: location }} />

  }
 
  return children;
};

export default ProtectedRoute;