import {
    Container,
    Row,
    Col,
    Form,
  } from "react-bootstrap";
  import { ArrowRight } from "react-bootstrap-icons";
  import { useState } from "react";
  import "./forgot-password.css";
  import ButtonPrimary from "../../components/buttons/buttons";
  import { BaseAPI } from "../../providers/base-api";
  import { useAuth } from "../../hooks/useAuth";
  import { ForgotFormData } from "../../models/auth";
import ModelWindow from "../../components/Modal/ModelWindow";
  
  interface ForgotFieldErrors {
    email?: string;
    form?: string;
  }
  interface ForgotPageProps {
    onLoginSuccessful: () => void;
    handleModelPopup: (event: any, type: string) => void;
    redirectTo?: string;
    closeModel?: any;
  }
  const ForgotForm = (props: any,{ onLoginSuccessful, handleModelPopup, redirectTo, closeModel, }: ForgotPageProps) => {
    const [validated, setValidated] = useState(false);
    const [hasError, setHasError] = useState(false)
    const [successmsg, setSuccessMessage] = useState(false);
    const [inProgress,setInProgress]=useState(false) 
    const [errors, setErrors] = useState<ForgotFieldErrors>({
      email: "",
    });
    const [form, setForm] = useState<ForgotFormData>({ email: "" });
    const { login } = useAuth();
  
    const [modalShow, setModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleChange = (fieldName, value) => {
      setForm((prevState) => ({ ...prevState, [fieldName]: value }));
      if (!!errors[fieldName])
        setErrors({
          ...errors,
          [fieldName]: null,
        });
    };
  
    const validateForm = () => {
      const { email } = form;
      const newErrors: ForgotFieldErrors = {};
      if (!email || email === "") newErrors.email = "This field is required";
  
      return newErrors;
    };
  
    const handleSubmit = async (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      const newErrors = validateForm();
      setValidated(true);
      setInProgress(true)
      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) {
        return;
      }
    
      try {
        setValidated(true);
        await BaseAPI.forgotPassword(form, (result: any) => {
          if (result?.data?.statusCode === 202) {
            setSuccessMessage(true);
          } 
        
          else {
            setValidated(true)
            setSuccessMessage(false);
            setErrors({ form: 'Invalid Email ' })
          }
        });
       
      } catch (error) {
        setErrorMessage(error?.response?.data?.message)
        setErrors({ form: 'Invalid Email ' })
        setHasError(true)
        setValidated(true)
        setInProgress(false)
      }
     
    };
    return (
      <div
        className="signup-container layout-container-content d-flex flex-fill flex-column"
        style={{ marginTop: "59px" }}
      >
        <div className="d-flex flex-fill">
          <main role="main" className="layout-main flex-fill">
            <Container className="forgot">
              <div className="forgot-main">
                <div className="row" style={{ marginTop: "1rem" }}>
                  <div className="col-1"></div>
                  <div
                    className="col-3 content"
                    style={{
                      marginLeft: "-2.5rem",
                      marginRight: "4rem",
                      backgroundColor: "white",
                      height: "0px",
                    }}
                  >
                    <h1 className="maintitle">Forgot Password</h1>
                    <h3 className="subtitle">
                    Reset your password by entering your registered email. We’ll send you an email with instructions{" "}
                    </h3>
                  </div>
                  <div className="col-7 form">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        <Col>
                          <Form.Control
                           className={`form-control input-lg email ${hasError ? 'is-invalid' : ''}`}
                            type="email"
                            placeholder="Email address *"
                            required
                            value={form.email}
                            disabled={inProgress}
                            name="email"
                            isInvalid={!!errors.email}
                            onChange={(e: any) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      <Row >
                   
                {errors.form &&
                  <div className="h6-align">
                    <span >The email address you provided is not registered. Please make sure you have entered the correct email address, and if you need additional support, please reach out to us at infrastructure@hraadvisors.com.</span>
                  </div>
                }
               
               {successmsg &&
                  <div className="success-msg">
                    <span >We've sent reset instructions to your registered email</span>
                  </div>
                }
                      </Row>
                      <ModelWindow
                    size="xl"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    body={<ForgotForm />}
                  />
                      <div className="col">
                        <ButtonPrimary className="btn-forgot" type="submit" disabled={inProgress}>
                          {" "}
                          Submit &nbsp; 
                          <ArrowRight className="arrow" size={28} />{" "}
                        </ButtonPrimary>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Container>
          </main>
        </div>
      </div>
    );
  };
  export default ForgotForm;
  