import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Popover, Row, Spinner, Table } from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/buttons";
import { BaseAPI } from "../../providers/base-api";
import { Link, useNavigate } from "react-router-dom";
import TitleCard from "../../components/title-card/titlecard";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { sortDataProgram } from "../../utils/common-utils";
import "./fundingeligibility.css";
import { ArrowLeft } from "react-bootstrap-icons";

import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import { ProjectContextType, ProjectContext } from "../project/project";
import { Project } from "../../models/project";
import { formatCurrency } from "../../utils/common-utils";
import img4 from "../../assets/images/info-circle.svg";
import ScrollToTop from "../../components/utilities/scroll";
import { BackButton, ButtonLearnmore, ButtonReadiness, DocumentLinkColor, EligibilityLinkIcon, LinkColor, ButtonPrint } from "../../components/styles/ThemeSwitching.styled";
import {  LinkIcon } from "../../components/styles/ThemeSwitching.styled";
import { AiOutlineFile, AiOutlineSelect } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { BsClock } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import dayjs from "dayjs";


export default function FundingEligibility() {

  const projectContext: ProjectContextType = useContext(ProjectContext)
  const [isSorting, setIsSorting] = useState(true)
  // const projectId = params && params.id ? +params.id : undefined;
  const [fundingData, setFundingData] = useState([]);
  const [programData, setProgramData] = useState([]);

  const onSubmit = async () => {
    try {
      if (projectContext.project.id === 'draft' || !projectContext.project.id) {
        await projectContext.saveProject((savedProjectId) => {
          navigate(`/projects/${savedProjectId}/pca/intro`)
        });
      } else {
        navigate(`/projects/${projectContext.project.id}/pca/intro`)
      }
    } catch (error) {
      console.log(error);
    }
  };


  const [isLoading, setIsLoading] = useState(false);

  const sortTableData = (order: boolean = false) => {
    setIsSorting(order)
    let data = sortDataProgram(fundingData, 'fundingProgramName', order ? 'asc' : 'desc');
    setFundingData(data);
  }
  function getStatusClassName(status) {
    switch (status) {
        case 'Due':
        case "Deadline approaching":
          return 'status-due';
        case 'Expired':
        case 'Expired Program':
            return 'status-expired';
        case 'TBA':
        case 'Next Milestone to be Announced':
            return 'status-tba';
        default:
            return '';
    }
}
  const processFundingProgram = (programTablePrefix, fundingProgram) => {
    let programStatus = fundingProgram.programStatus || fundingProgram.nofoLabel || fundingProgram.nofolabel
    fundingProgram.dueDate = fundingProgram.dueDate || fundingProgram.duedate
         

    if(["Expired Program", "Expired"].includes(programStatus)) {
      programStatus = 'Expired Program'
    } else if(["Deadline approaching", "Due"].includes(programStatus) || (!programStatus && fundingProgram.dueDate)) {
      programStatus = 'Deadline approaching'
    } 
    if(!["Expired Program", "Deadline approaching", "Next Milestone to be Announced"].includes(programStatus) && fundingProgram.dueDate) {
      programStatus = 'Deadline approaching'
    }        
    const dueDate = new Date(fundingProgram.dueDate);
    const currentDate = new Date();   
    if (currentDate > dueDate && !["Next Milestone to be Announced"].includes(programStatus)) {      
        programStatus = 'Expired Program';
    }
    
    fundingProgram.programStatus = programStatus
    fundingProgram.id = fundingProgram.id || fundingProgram.fundingProgramId
    fundingProgram.rowKey = `${programTablePrefix}-${fundingProgram.id}`
    
    if (fundingProgram.dueDate && programStatus === 'Deadline approaching') {
      const dueDate = new Date(fundingProgram.dueDate);
      const currentDate = new Date();
      if (currentDate > dueDate) {
          fundingProgram.programStatus = 'Expired Program';
      } else {
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const formattedDate = `${monthNames[dueDate.getMonth()]} ${dueDate.getDate()}, ${dueDate.getFullYear()}`;
          fundingProgram.formattedDate = formattedDate;
          fundingProgram.programStatusDisplay = `Due: ${dueDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
      }
  }
    if(!fundingProgram.programStatus) {
      fundingProgram.programStatusDisplay = undefined
    } else {
      fundingProgram.programStatusDisplay = fundingProgram.programStatusDisplay || fundingProgram.programStatus
    }

    if (!dayjs(fundingProgram.dueDate).isValid()) {
      fundingProgram.dueDate = null;
    }
    return fundingProgram
  }

  useEffect(() => {


    const getFundingEligibility = async (project: Project) => {
      setIsLoading(true)
      try {
        await BaseAPI.getFundingEligibility(project, (result: any) => {

          setIsLoading(false)
          if (result.status === 200) {
            const enablePca = result.data?.results?.length > 0;
            projectContext.setEnablePca(enablePca)

            let final = result.data.map(data => {
              const processedRecord = processFundingProgram('ep', data);
            return processedRecord;
           
            })    
           
            setFundingData(final);
          } else {
            console.log(result.message);
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    getFundingEligibility(projectContext.project);
  }, [projectContext.project, projectContext]);

  const navigate = useNavigate();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong>Disclaimer: </strong>Based on publicly available information from the federal government, your project could meet the criteria for eligibility for this funding program. HR&A cannot guarantee eligibility or funding for your project.
      </Popover.Body>
    </Popover>
  );
  const tenantId = process.env.REACT_APP_TENANT;
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredPrograms = selectedStatus
    ? fundingData.filter(program => program.programStatus === selectedStatus)
    : fundingData;

  
  return (

    <>
      <ScrollToTop />
      <div className="funding-eligibility-container layout-container-content d-flex flex-fill flex-column page-container">
        <div className="d-flex flex-fill ">
          <main role="main" className="layout-main flex-fill ">
            <Container>
              <div className="main">
              
                {tenantId === "1" && (
                    <><TitleCard
                    title="Funding Eligibility" />
                    <p className="funding-text">
                      The IFN Beta considers projects eligible for a funding program when they align with federally identified categories. The IFN Beta is a free tool that offers guidance for federal funding and project readiness assessment—a good first step when trying to understand what federal funding opportunities might align with your project. While HR&A cannot guarantee project funding or the comprehensiveness, accuracy, or timeliness of the information provided, we encourage you to contact us at <LinkColor href="mailto:infrastructure@hraadvisors.com">infrastructure@hraadvisors.com</LinkColor> if you need additional support with the next steps of your funding assessment and submission process.
                    </p></>
    )}

    {tenantId === "2" && (
        <>
        <h3 className="heding-alignment">Competitive Funding Opportunities Curated for Puerto Rico</h3>
        
                    <p className="funding-text">
                      The La Liga Federal Funding Navigator considers projects eligible for a funding program when they align with federally identified categories. The La Liga Federal Funding Navigator is a free tool that offers guidance for federal funding and project readiness assessment—a good first step when trying to understand what federal funding opportunities might align with your project. While La Liga cannot guarantee project funding or the comprehensiveness, accuracy, or timeliness of the information provided, we encourage you to contact us at <LinkColor href="mailto:info@ligadeciudadespr.com">info@ligadeciudadespr.com</LinkColor> if you need additional support with the next steps of your funding assessment and submission process.      </p></>
    )}
                <br></br>
                <Row className="justify-content-md-center layout-main funding-eligibility-container-tit-tbl-gap">
                  <Col>
                    {(!fundingData || fundingData.length === 0) && !isLoading ?
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col>
                          <p className="title-body funding-eligibility-nodata">Based on the information you provided, the IFN has not identified any federal funding opportunities.</p>
                        </Col>
                      </Row>
                      : ''}
                    {isLoading ? <Spinner className="funding-eligibility-spinner-loader-ftbl" animation="border" variant="info" /> : null}
                    {fundingData?.length > 0 &&
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col>
                         {tenantId === "2" && (
                                  <>
                                    <div className="position-relative">
                                      <IoFilterSharp className="funding-filter-icon" />
                                      <Form.Select
                                        id="programStatusDropdown"
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        className="funding-program-status dropdown-select"
                                      >
                                        <option value="">All</option>
                                        {fundingData
                                          .map(program => program.programStatus)
                                          .filter(status => status)
                                          .filter((value, index, self) => self.indexOf(value) === index)
                                          .map((status, index) => (
                                            <option key={index} value={status}>{status}</option>
                                          ))}
                                      </Form.Select>
                                    </div>
                                  </>
                                )} 
                          <Table hover className="" id="fe-programs-table">
                            <thead className={`funding-eligibility-container-tb-head-tenant${process.env.REACT_APP_TENANT} === 1 ? "1" : "2"}`}>
                            
                              <tr>
                                <th scope="col" className="side-lef funding-eligibility-container-col-program-name">
                                  <span >Program Name{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)}
                                  /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)}
                                  />} </span>
                                </th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-type-of-project">Type of Project</th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-project-category">Project Category</th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-program-funding">Amount</th>
                                <th scope="col" className="side-lef funding-eligibility-container-col-program-fundtype">Fund Type</th>

                                <th scope="col" className="side-lef funding-eligibility-container-col-eligibility eligibility-body-style">
                                  <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
                                    <img className="two-layer-icon " alt="information icon" data-tip data-for="registerTip" src={img4} />
                                  </OverlayTrigger>
                                  Eligibility</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>

                            {filteredPrograms.map((result: any, index: number) => (

                              <tbody key={`#rb${index}`}>
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#r${index}`}
                                  className="funding-eligibility-container-tb-body"
                                >  
                                  
                                  <td className="funding-eligibility-container-tbl-hd-1" >
                                  {
                                        result?.programStatus ? (
                                          <>
                                            <p className={`badge-nofo-label ${getStatusClassName(result?.programStatus)}`}>
                                              {["Expired Program", "Expired"].includes(result.programStatus) ? <GrClose className="close-icon-size" /> : <BsClock className="clock-icon-size" />} {result.programStatusDisplay}

                                            </p>
                                          </>
                                        ) : <></>
                                      }

                            {result?.fundingProgramName}
                                  </td>
                                  
                                  <td className="funding-eligibility-container-drop-body ">{result?.typeOfProjectName}</td>
                                  <td className="funding-eligibility-container-drop-body">{result?.projectCategoryName}</td>

                                  <td className="funding-eligibility-container-drop-body funding-eligibility-container-amount-r">
                                    {formatCurrency(result?.amount)}
                                  </td>
                                  <td className="funding-eligibility-container-drop-body">
                                  <div className="badge">
                              {result?.ProjectFundingType}
                            </div>
                                  </td>
                                  <td >
                                    <ButtonPrimary className="funding-eligibility-container-btn-style funding-eligibility-container-btn-eligible-pad">
                                      Eligible
                                    </ButtonPrimary>
                                  </td>
                                  <td scope="row">
                                    <IoIosArrowDown 
                                    className="fe-program-drp-down" 
                                    />
                                    <IoIosArrowUp className="fe-program-drp-up" />
                                  </td>
                                </tr>
                                <tr
                                  className="collapse accordion-collapse funding-eligibility-container-accordian"
                                  id={`r${index}`}
                                  data-bs-parent=".table"
                                >
                                  <td colSpan={8}>
                                    <Row className="funding-eligibility-container-colspan-drop-body">
                                      <Col>
                                        <h4 className="funding-eligibility-container-drop-head">
                                          Eligible Recipients:
                                        </h4>
                                        <p className="funding-eligibility-container-drop-body funding-eligibility-container-elibible">
                                          {
                                            result?.eligibleRecipients
                                          }
                                        </p>
                                       
                                      </Col>
                                      <div>
                                        <h4 className="eligibility-program-recipients">Cost Share</h4>
                                          <div className="cost-share">
                                            <div className="cost-share-text">
                                             
                                                <h3 translate="no" className="notranslate">
                              {result?.federalShare || '0%'}
                            </h3>
                                              <span className="funding-eligibility-fedralShare">Federal Share</span>
                                            </div>
                                            <div>
                                              <h3 translate="no" className="notranslate" >  {result?.stateShare  || '0%'} </h3>
                                              <span className="funding-eligibility-fedralShare">State Share</span>
                                            </div>
                                          </div>
                                      </div>
                                
                                      {/* <Col>
                                        <h4 className="funding-eligibility-container-drop-head">Disclaimer:</h4>
                                        <p className="funding-eligibility-container-drop-body funding-eligibility-container-disclaimer">
                                          Cras mattis consectetur purus sit amet fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                        </p>
                                      </Col> */}
                                      <Row className="funding-eligibility-container-purpose-mt">
                                        <Col>
                                          <h4 className="funding-eligibility-purpose">Purpose:</h4>
                                          <p className="funding-eligibility-purpose-body  funding-eligibility-container-drop-body-purposes">
                                            {result?.purpose}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col >
                                          {
                                            result?.link ?
                                              <ButtonLearnmore className="funding-eligibility-container-btn-learn"
                                                href={result?.link}
                                                target='_blank'
                                                rel="noreferrer"
                                              >
                                                LEARN MORE
                                              </ButtonLearnmore> : null
                                          }
                                        </Col>

                                      </Row>
                                      <Row className="funding-eligibility-container-purpose-mt">
                                        {result.fundingProgramLink && result.fundingProgramLink.length > 0 && (
                                          <>
                                            <Row>
                                              <Col>
                                                {result.fundingProgramLink.some(link => ['nofa', 'nofo'].includes(link.type) && link.label) && (
                                                  <h4 className="eligiblility-links">Documents:</h4>
                                                )}
                                                {result.fundingProgramLink
                                                  .filter(link => ['nofa', 'nofo'].includes(link.type) && link.label)
                                                  .map((documentLink, index) => (
                                                    <><EligibilityLinkIcon className="eligibility-text-style"> </EligibilityLinkIcon><span key={index} className="eligible-program-purpose-drop-body">
                                                      <DocumentLinkColor href={documentLink.hyperlink} target="_blank" rel="noopener noreferrer">
                                                        <LinkIcon >
                                                          <AiOutlineFile />
                                                        </LinkIcon> {documentLink.label}
                                                       
                                                      </DocumentLinkColor>
                                                      <LinkIcon className="eligibility-icon-style">  {documentLink.type === 'nofa' ? 'NOFA' : 'NOFO'}   </LinkIcon>
  
                                                    </span>
                                                    </>
                                                  ))}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                {result.fundingProgramLink.some(link => ((link.type === 'link' || !link.type) && link.label)) && (
                                                  <h4 className="eligiblility-links">Additional Links:</h4>
                                                )}
                                                {result.fundingProgramLink
                                                  .filter(link => ((link.type === 'link' || !link.type) && link.label))
                                                  .map((additionalLink, index) => (
                                                    <span key={index} className="eligible-program-purpose-drop-body">
                                                      <DocumentLinkColor href={additionalLink.hyperlink} target="_blank" rel="noopener noreferrer">
                                                        <LinkIcon className="eligible-icon-style">
                                                          <AiOutlineSelect />
                                                        </LinkIcon> {additionalLink.label}
                                                      </DocumentLinkColor>
                                                    </span>
                                                  ))}
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Row>
                                    </Row>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </Table>
                        </Col>
                        <Row className="funding-eligibility-container-btn-cot funding-row">
                        </Row>
                      </Row>
                    }
                  </Col>
                </Row>
                <Row className="bottom-padding">
                  <Col className="funding-eligibility-container-btn-back-align-top">
                    <BackButton className="funding-eligibility-container-btn-back" href={`/projects/${projectContext.project.id || 'draft'}`} >
                      <ArrowLeft
                        className="arrow funding-eligibility-container-arrow-spacing"
                        // color="#aeb0b5"
                        size={25}
                      />
                      Back
                    </BackButton>
                  </Col>
                  <Col className="p-0">
                    <ButtonReadiness
                      className="funding-eligibility-container-btn-align"
                      onClick={onSubmit}
                    >
                      READINESS ASSESSMENT
                    </ButtonReadiness>
                   
                  </Col>
                 
                </Row>
              </div>
            </Container>
          </main>
        </div>
      </div>
    </>
  );
}

