import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ButtonPrimary from "../../components/buttons/buttons";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import ScoreCard from "../score-card/score-card";

import DoughnutChartWithCenterText from "./doughnut-center";
import { ProjectContextType, ProjectContext } from "../project/project";
import "./scorecard-chart.css";
import  { ThemeProvider } from "styled-components";
import {  ButtonPrint, LinkColor, ShowReadiness } from "../../components/styles/ThemeSwitching.styled";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FiPrinter } from "react-icons/fi";
import { timeLog } from "console";

interface FederalPriorityScore {
    priorityAreaName: string;
    score: number;
    maxScore?: number;
    scorePct?: number;
    percentage?: number;
}

const ScorePrioritySummary = () => {
    const [showTable, setShowTable] = useState(false);
    const [printPage, setPrintPage] = useState(false);

    const params = useParams();
    const projectId = params && params.id ? +params.id : undefined;
    const projectContext: ProjectContextType = useContext(ProjectContext);
    const [priorityScoreData, setPriorityScoreData] = useState<FederalPriorityScore[]>([]);

    const height = 140;
    const options = {
        height: height,
        width: height,
        cutout: 85
    }

    const getPriorityScores = useCallback(async (defaultPriorityScoreData: FederalPriorityScore[]) => {
        await BaseAPI.getPriorityScores(projectId, (result: any) => {
            if (result.status === 200) {
                const projectReadinessAssessment = result.data?.data.projectReadinessAssessment;
                const projectReadinessAssessmentArr:FederalPriorityScore[] = projectReadinessAssessment ? Object.keys(projectReadinessAssessment).map(key => {
                    
                    const priorityRowObj = projectReadinessAssessment[key];
                    const priorityAreaObj:FederalPriorityScore = {
                        priorityAreaName: priorityRowObj.title,
                        score: priorityRowObj.score,
                        maxScore : priorityRowObj.maxScore,
                        scorePct: priorityRowObj.percentage
                    }
                    
                    return priorityAreaObj
                }) : []
                const priorityScoreResult: FederalPriorityScore[] = projectReadinessAssessmentArr;
                const priorityScoreSumary = priorityScoreResult.map(priorityScoreRow => {
                    priorityScoreRow.score = priorityScoreRow.score || 0;
                    priorityScoreRow.score = priorityScoreRow.maxScore === 0 ? 0 : Math.round((priorityScoreRow.score / priorityScoreRow.maxScore) * 100);
                    priorityScoreRow.scorePct = priorityScoreRow.percentage || priorityScoreRow.scorePct || 0;
                    return priorityScoreRow;
                })
                if(!priorityScoreSumary || priorityScoreSumary.length === 0) {
                    setPriorityScoreData(defaultPriorityScoreData)
                } else {
                    setPriorityScoreData(priorityScoreSumary)
                }
            } else {
                setPriorityScoreData([])
                console.log(result.message);
            }

        });
    }, [projectId])

    useEffect(() => {
        const defaultPriorityScoreData: FederalPriorityScore[] = [
            { priorityAreaName: 'Project Design', score: 0, scorePct: 0 },
            { priorityAreaName: 'Sustainability', score: 0, scorePct: 0 },
            { priorityAreaName: 'Planning', score: 0, scorePct: 0 },
            { priorityAreaName: 'Governance', score: 0, scorePct: 0 },
            { priorityAreaName: 'Implementation', score: 0, scorePct: 0 },
        ];
        getPriorityScores(defaultPriorityScoreData);
        // Get data from API and set here.
    }, [getPriorityScores, projectId])


    const [selectedTheme, setSelectedTheme] = useState();
    const [isEnabled,setEnabled] = useState(true)
  
    useEffect(() => {
     
      const currentTheme = JSON.parse(localStorage.getItem("current-theme"));        
          setSelectedTheme(currentTheme);
          setEnabled(false)
      
    }, []);
    const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
    const tenantId = process.env.REACT_APP_TENANT;
    const tableRef = useRef(null);
    const titleRef =  useRef(null)

    const [pdfDownloaded, setPdfDownloaded] = useState(false);   
    const downloadPDF = () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
    
        const titleElement = titleRef.current.querySelector('.sc--hide-btn');
        if (titleElement) {
            titleElement.style.display = 'none';
        }
    
        html2canvas(titleRef.current)
            .then(titleCanvas => { 
                html2canvas(tableRef.current)
                    .then(tableCanvas => {
                        
                        const combinedCanvas = document.createElement('canvas');
                        const context = combinedCanvas.getContext('2d');
    
                        const totalHeight = titleCanvas.height + tableCanvas.height;
                        const width = Math.max(titleCanvas.width, tableCanvas.width);
    
                        combinedCanvas.width = width;
                        combinedCanvas.height = totalHeight;
    
                        context.drawImage(titleCanvas, 0, 0, width, titleCanvas.height);
                        context.drawImage(tableCanvas, 0, titleCanvas.height, width, tableCanvas.height);
    
                        const imgData = combinedCanvas.toDataURL('image/png');    
                        const currentDate = new Date().toLocaleDateString('en-GB', { 
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }).split('/').join('-');
    
                        const fileName = `score-card_${currentDate}.pdf`; 
    
                        pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
    
                        pdf.save(fileName);
                        setPdfDownloaded(true);
                        setShowTable(true); 
                        setEnabled(true); 
                    })
                    .catch(error => {
                        console.error('Error capturing table:', error);
                        setShowTable(true); 
                        setEnabled(true); 
                        setPdfDownloaded(true);

                    });
            })
            .catch(error => {
                console.error('Error capturing title:', error);
            });
    };

    const handleButtonClick = () => {
        if(!showTable) {
            // console.log('Showing table')
            setShowTable(true);
        }
        console.log('Setting up timer', new Date().toTimeString())
        const timeoutId = setTimeout(() => {
            // console.log('Timer triggered', new Date().toTimeString())
            downloadPDF();            
        }, 1000);
    };
  
    const handleTableData = (data) => {
        return data
    };
  
    return (
        <ThemeProvider theme={currentTheme}>
        <div className="scorecard-container layout-container-content d-flex flex-fill flex-column project-container" id="scorecard-container" >
            <div className="d-flex flex-fill ">
                <main role="main" className="layout-main flex-fill">
                    <Container >
                        <div className="main"   ref={titleRef}>
                            <div>
                          
                              <h3 className="federal-title-align">Federal Priority Overview</h3>
   {tenantId === "1" && (
      <p className="fedral-overview" >
The IFN Beta assesses project readiness based on HR&A’s interpretation of successful 
                                alignment with federal funding priorities. While this tool offers insights into your 
                                project’s readiness for applying for federal funding, the Project Readiness 
                                Breakdown below is meant to be informational and does not guarantee nor offer 
                                access to federal funding. The IFN Beta assesses readiness and is not an application
                                for federal funding. Please contact <LinkColor href="mailto:infrastructure@hraadvisors.com">infrastructure@hraadvisors.com</LinkColor> if you need 
                                additional support in assessing your project or preparing for funding applications.</p>
    )}

    {tenantId === "2" && (
      <p className="fedral-overview" >
The La Liga Federal Funding Navigator assesses project readiness based on La Liga’s interpretation of successful alignment with federal funding priorities. While this tool offers insights into your project’s readiness for applying for federal funding, the Project Readiness Breakdown below is meant to be informational and does not guarantee nor offer access to federal funding. The La Liga Federal Funding Navigator assesses readiness and is not an application for federal funding. Please contact <LinkColor href="mailto:info@ligadeciudadespr.com">info@ligadeciudadespr.com</LinkColor>  if you need additional support in assessing your project or preparing for funding applications.</p> 
    )}
</div>

                            
                            <br />
                            <div className="arrange-horizontally sc-card-ht" >
                                {priorityScoreData.map((dataItem, chartIndex: number) => {
                                    return (
                                        <div className="single-doughnut" key={chartIndex}>
                                            <DoughnutChartWithCenterText options={{ ...options, metric: dataItem.scorePct, label: dataItem.priorityAreaName }} />
                                        </div>
                                    )
                                })}
                            </div>
                            {projectContext?.hasUnansweredQuestions ? <span className='score-card-errormsg'><p>* Note that one or more PRA questions are unanswered. Current assessment is based on the questions attempted so far and is not representative of the entire PRA.</p></span> : null}
                            <div>
                                <div>
                                <Row className="sc--hide-btn">
                                        {!pdfDownloaded && (
                                            <>
                                                <ShowReadiness className="project-listing-btn-align btn-align-width" onClick={() => setShowTable(!showTable)}>
                                                    {`${showTable ? 'HIDE' : 'SHOW'}`} READINESS BREAKDOWN {`${showTable ? '-' : '+'}`}
                                                </ShowReadiness>
                                                {tenantId === "2" && (
                                                    <ButtonPrint onClick={handleButtonClick}>
                                                        PRINT <FiPrinter />
                                                    </ButtonPrint>
                                                )}
                                            </>
                                        )}
                                    </Row>
                                    {pdfDownloaded && (
                                        <div>
                                            <Row className="sc--hide-btn">
                                                <ShowReadiness className="project-listing-btn-align btn-align-width" onClick={() => setShowTable(!showTable)}>
                                                    {`${showTable ? 'HIDE' : 'SHOW'}`} READINESS BREAKDOWN {`${showTable ? '-' : '+'}`}
                                                </ShowReadiness>
                                                {tenantId === "2" && (
                                                    <ButtonPrint onClick={handleButtonClick}>
                                                        PRINT <FiPrinter />
                                                    </ButtonPrint>
                                                )}
                                            </Row>
                                        </div>
                                    )}
                                </div>
                                <div>
          
                                    </div>
                               
                            </div>
                        </div>
                    </Container>

                </main>
            </div>
            <Container ref={tableRef}>
            {showTable && <div ><ScoreCard onTableData={handleTableData} />
         
            </div>}
          
            </Container>
        </div>
        </ThemeProvider>
    )
}

export default ScorePrioritySummary


