import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { blue, green } from "./components/styles/Theme.styled";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if(Number(process.env.REACT_APP_TENANT) === 1){
  localStorage.setItem("current-theme", JSON.stringify(blue));
}
else{
  localStorage.setItem("current-theme", JSON.stringify(green));
}

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
