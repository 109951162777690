import React, { useEffect, useState } from 'react'
import "./footer.css";
import { FooterColor } from '../styles/ThemeSwitching.styled';
import styled, { ThemeProvider } from 'styled-components';
import { blue, green } from '../styles/Theme.styled';
function Footer() {
  

  return (
    <><div className=''>
         				<FooterColor className="h6"> © 2024 HR&A Advisors, Inc. All rights reserved. <a href ="https://www.hraadvisors.com/privacy-policy/" target="blank"> &nbsp; Privacy Policy</a>&nbsp;&nbsp;<a href ="https://www.hraadvisors.com/terms-of-use/" target="blank"> Terms and Conditions</a> </FooterColor>  
                           
      </div>
      </>
    
  )
}

export default Footer