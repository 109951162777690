import { LocalStorageConfig } from "localstorage-slim/dist/types";
import millify from "millify";
import Constants from "./constants";

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
export function sortData(data: any[], sortByField: string, isAscending: boolean) {
    function compare(a, b) {
        if (a[sortByField] < b[sortByField]) {
            return isAscending ? -1 : 1;
        }
        if (a[sortByField] > b[sortByField]) {
            return isAscending ? 1 : -1;
        }
        return 0;
    }

    return data.sort(compare);
}
export function formatCurrency(value:string){
    if(!value) {
      return ''
    }
    try {
      const valueWoCurrncy = value.replace('$','').replace(/,/g,'');
      const valueInNumber = parseFloat(valueWoCurrncy);
      // console.log(valueWoCurrncy, valueInNumbers)
      const valueInLocaleCurrency = millify (valueInNumber, {
        precision: 2,
        lowercase: true,
        space: true,
        units: ['','K','million','billion','trillion']
      });

//   const valueInLocaleCurrency = valueInNumber.toLocaleString(undefined,{maximumFractionDigits: 0}) 
      return `\$${valueInLocaleCurrency}`;
    } catch(error) {
      return value
    }
}
export function sortDataProgram(data: any, field: string, order = 'asc') {

  if(!data || data.length === 0) {
    return data;
  }
  let sortedData = [];
  if (order == 'asc') {
      sortedData = data.sort((a, b) => (a[field] < b[field]) ? -1 : 1);
  } else {
      sortedData = data.sort((a, b) => (a[field] > b[field]) ? -1 : 1);
  }
  return sortedData;
};

export function getDefaultLocalStorageOptions(encrypt?: boolean) {
  const options:LocalStorageConfig  = {encrypt: true, ttl: 12 * Constants.HOUR_IN_SECS};
  if(encrypt) {
      options.secret = Number.isInteger(+process.env.REACT_APP_SECRET) ?  +process.env.REACT_APP_SECRET : process.env.REACT_APP_SECRET;
    }

  return options
}
