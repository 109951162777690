import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/header";
import { GlobalRoutesMap } from "./routes";
import { AuthProvider } from "./hooks/useAuth";

import { BrowserView, MobileView } from "react-device-detect";
import { Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import Footer from "./components/footer/footer";
import { blue, green } from "./components/styles/Theme.styled";
import { ThemeContainer } from "./components/styles/ThemeSwitching.styled";
import styled, { ThemeProvider } from "styled-components";
import { appendTranslateElement, googleTranslateScriptInit } from "./utils/googleTranslate";
function App() {
  const desktopView = useMediaQuery("(min-width:1024px)");

  const [selectedTheme, setSelectedTheme] = useState(blue);
  const [isCircleImgVisible, setisCircleImgVisible] = useState(true);
  const disableTranslation = process.env.DISABLE_TRANSLATION && process.env.DISABLE_TRANSLATION === 'true';

  useEffect(() => {

    if(Number(process.env.REACT_APP_TENANT) === 1){
      setSelectedTheme(blue);
      localStorage.setItem("current-theme", JSON.stringify(blue));
      setisCircleImgVisible(true);

    }
    else{
      setSelectedTheme(green);
      localStorage.setItem("current-theme", JSON.stringify(green));
      setisCircleImgVisible(false);

    }
   
    const selectedTheme = JSON.parse(localStorage.getItem("current-theme"));
    setSelectedTheme(selectedTheme);
  }, []);

  
  return (
    <div className="App">
    <ThemeProvider theme={selectedTheme}>
      <AuthProvider>
        <Header />
        {!desktopView ? (
          <>
          <MobileView className="app-mob-view-dis d-flex align-items-center justify-content-center">
            <Col>
              <h3 className="app-head-design">Desktop view only</h3>
              <p className="app-para-design">
                To access the site please view this link a desktop browser
              </p>
            </Col>
          </MobileView>
            <BrowserView className="app-mob-view-dis d-flex align-items-center justify-content-center text-center">
            <Col>
              <h3>Desktop view only</h3>
              <p>
                To access the site please view this link a desktop browser
              </p>
            </Col>
          </BrowserView>
          </>
        ) :  (
          <BrowserView>
            <GlobalRoutesMap />
          </BrowserView>
        ) }
               {!disableTranslation && appendTranslateElement() && googleTranslateScriptInit()}

      </AuthProvider>
      <Footer />
      </ThemeProvider>
      
    </div>
  );
}

export default App;
