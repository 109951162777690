import Axios, { AxiosRequestConfig, Method } from 'axios';
import { sortData } from '../components/utilities/datatable';
import { Project } from '../models/project';
import { CODETABLE_TYPES, SelectOption } from './models/codetable';

export class BaseAPI {
  static getAPIBaseURI() {
    const BASE_API_URL = process.env.REACT_APP_API_URL;

    return BASE_API_URL;
  }

  static buildAPIURL(apiPath: string) {
    const baseURL = BaseAPI.getAPIBaseURI();
    let prefix = "";
    if (!apiPath?.startsWith("/")) {
      prefix = "/";
    }

    const apiUrl = `${baseURL}${prefix}${apiPath}`;

    return apiUrl;
  }

  private static async apiRequest({
    method,
    path,
    formdata,
    queryParams,
    additionalHeaders
  }: {
    method: Method,
    path: string,
    formdata?: any,
    queryParams?: { [key: string]: any },
    additionalHeaders?: { [key: string]: any }
  }, callback: any) {
    const url = new URL(this.buildAPIURL(path));
    if (queryParams) {
      Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'tenantid': `${process.env.REACT_APP_TENANT || 1}`,
      ...additionalHeaders
    };
  
    const config: AxiosRequestConfig= {
      method,
      url: url.toString(),
      headers,
      ...(formdata ? { data: formdata } : {})
    };
  
    try {
      const response = await Axios.request(config);
      callback(response);
    } catch (error) {
      // Assuming the error handling is done similarly as in the provided methods
      callback( {
        status: error.response.status,
        message: error.response.data?.error ? error.response.data.error : 'General server error',
      });
    }
  }

  private static async apiRequestAsync({
    method,
    path,
    formdata,
    queryParams,
    additionalHeaders
  }: {
    method: Method,
    path: string,
    formdata?: any,
    queryParams?: { [key: string]: any },
    additionalHeaders?: { [key: string]: any }
  }) {
    const url = new URL(this.buildAPIURL(path));
    if (queryParams) {
      Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'tenantid': `${process.env.REACT_APP_TENANT || 1}`,
      ...additionalHeaders
    };
  
    const config: AxiosRequestConfig= {
      method,
      url: url.toString(),
      headers,
      ...(formdata ? { data: formdata } : {})
    };
  
    // try {
      const response = await Axios.request(config);
      return response;
    // } catch (error) {
    //   // Assuming the error handling is done similarly as in the provided methods
    //   throw new Error( {
    //     status: error.response.status,
    //     message: error.response.data?.error ? error.response.data.error : 'General server error',
    //   });
    // }
  }
  
  static async login(formdata: any, callback: any) {
    this.apiRequest({
      method: 'POST',
      path: "/auth/login",
      formdata: formdata,
    }, callback);
  }
  
  static async users(formdata: any, callback: any) {
    this.apiRequest({
      method: 'POST',
      path: "/user/signup",
      formdata: formdata,
    }, callback);
  }
  

  static async getCodeTable(typeCodes: CODETABLE_TYPES[]) {
    const typeCodesParam = typeCodes ? `&typeCodes=${typeCodes.join(",")}` : "";
    const apiPath = `/code-table?excludeValues=false&includeInactive=false${typeCodesParam}`

    

    // const responseCallback = new Promise<{ [key: string]: SelectOption[] }>((resolve, reject) => {
    //   console.log()
    // });
  

    return this.apiRequestAsync({
      method: 'GET',
      path: apiPath,
    }).then((results) => {
      // console.log(results)
      if (!results) {
        return;
      }
      const codeTableOptions: { [key: string]: SelectOption[] } = {};
      const data = results.data.data;
      data.forEach((codeTable: any) => {
        if (codeTable.values && codeTable.values.length > 0) {
          const selectOptions: SelectOption[] = sortData(
            codeTable.values,
            "displaySequence",
            true
          ).map((codeValueItem) => {
            const codeValueOption = 
             {
              text: codeValueItem.name,
              value: codeValueItem.id,
              parentId: codeValueItem.parentId,
              label : codeValueItem.name,
            };
            if(codeTable.typeCode === CODETABLE_TYPES.PROJECT_CATEGORY) {
              codeValueOption['keywords'] = codeValueItem.keywords;
            }
            return codeValueOption;
          });
          codeTableOptions[codeTable.typeCode] = selectOptions;
        }
      });
      // console.log(codeTableOptions, codeTableOptions)
      return codeTableOptions;
    })
  }
  
  
  
  static async getCodeTable1(typeCodes: CODETABLE_TYPES[]) {
    const typeCodesParam = typeCodes ? `&typeCodes=${typeCodes.join(",")}` : "";
    const apiPath = BaseAPI.buildAPIURL(
      `/code-table?excludeValues=false&includeInactive=false${typeCodesParam}`
    );
    return fetch(apiPath, {headers: {
      'Content-Type': 'application/json',
      'tenantid': `${process.env.REACT_APP_TENANT || 1}`,
    }})
      .then((result) => result.json())
      .then((results) => {
      if (!results) {
          return;
        }
        const codeTableOptions: { [key: string]: SelectOption[] } = {};
        results.data.forEach((codeTable: any) => {
          if (codeTable.values && codeTable.values.length > 0) {
            const selectOptions: SelectOption[] = sortData(
              codeTable.values,
              "displaySequence",
              true
            ).map((codeValueItem) => {
              const codeValueOption = 
               {
                text: codeValueItem.name,
                value: codeValueItem.id,
                parentId: codeValueItem.parentId,
                label : codeValueItem.name,
              };
              if(codeTable.typeCode === CODETABLE_TYPES.PROJECT_CATEGORY) {
                codeValueOption['keywords'] = codeValueItem.keywords;
              }
              return codeValueOption;
            });
            codeTableOptions[codeTable.typeCode] = selectOptions;
          }
        });
        return codeTableOptions;
      });
  }

  static async addProjects(formdata: any, callback: any) {
    this.apiRequest({
      method: 'POST',
      path: "/projects",
      formdata: formdata,
    }, callback);
  }
  
  static async getCodeTableValues(id: number, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: `/code-table-values/${id}`,
    }, callback);
  }
  
  static async getAllCodeTableValues(callback: any) {
    this.apiRequest({
      method: 'GET',
      path: "/code-table-values",
    }, callback);
  }

  static async getAllFundingProgram(callback: any) {
    this.apiRequest({
      method: 'GET',
      path: "/funding-eligibility",
    }, callback);
  }
  
  static async getAllFundingEligibleProgram(callback: any) {
    this.apiRequest({
      method: 'GET',
      path: "/funding-eligibility/eligible-programs/user",
    }, callback);
  }
  
  static async getProjects(callback: any) {
    this.apiRequest({
      method: 'GET',
      path: "/projects",
    }, callback);
  }
  static async deleteProject(id: number, callback: any) {
    this.apiRequest({
      method: 'DELETE',
      path: `/projects/${id}`,
    }, callback);
  }
  
  static async getProjectDetail(projectId: number, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: `/projects/${projectId}`,
    }, callback);
  }

  static async getFundingEligibility(project: Project, callback: any) {
    const queryParams = {
      implementingEntityId: project.implementingEntityId,
      typeOfProjectId: project.typeOfProjectId,
      projectCategoryId: project.projectCategoryId,
      eligibleActivityId: project.eligibleActivityId,
      locationId: project.locationId,
      keywordIds: project.keywordIds,
      fundingTypeId: project.fundingTypeId,
      programStatusId: project.programStatusId,
      typeId: project.typeId
    };
  
    this.apiRequest({
      method: 'GET',
      path: "/funding-eligibility",
      queryParams: queryParams
    }, callback);
  }
  
  static async getProjectComponents(pcaId: number, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: "/pca-taxonomy/project-component",
      queryParams: { pcaId: pcaId }
    }, callback);
  }
  
  static async getProjectPCADetail(projectId: number | string, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: `/pca/default`,
      queryParams: {
        includeInactive: '',
        includeTaxonomyTree: '1',
        includeQuestions: '1',
        includeAnswers: '1',
        projectId: projectId
      },
    }, callback);
  }
  
  static async getProjectPCASummary(projectId: number | string, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: `/pca/default`,
      queryParams: {
        includeInactive: '',
        includeTaxonomyTree: '1',
        includeQuestions: '1',
        includeAnswers: '1',
        includeChoice: '1',
        projectId: projectId
      },
    }, callback);
  }
  
  static async getPriorityScores(projectId: number | string, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: `/prna/${projectId}`,
    }, callback);
  }
  
  static async getContent(typeCode: string, callback: any) {
    this.apiRequest({
      method: 'GET',
      path: `/content/${typeCode}`,
    }, callback);
  }
  
   static async saveQuestions(answerdata: any, callback: any) {
    this.apiRequest({
      method: 'POST',
      path: "/answer",
      formdata: answerdata,
    }, callback);
  }
  
  static async submitContactForm(formdata: any, callback: any) {
    this.apiRequest({
      method: 'POST',
      path: "/contact-us",
      formdata: formdata,
    }, callback);
  }
  
  static async forgotPassword(formdata: any, callback: any) {
    this.apiRequest({
      method: 'PUT',
      path: "/auth/forgot-password",
      formdata: formdata,
    }, callback);
  }
  
  static async resetPassword(formdata: any, callback: any) {
    this.apiRequest({
      method: 'PUT',
      path: "/auth/reset-password",
      formdata: formdata,
    }, callback);
  }
  
//theme
  static async getTheme(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/theme"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  //get tenant
  static async getTenant(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/tenant"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getFeatures(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/content/config"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


}

