import { Button, ButtonProps } from "react-bootstrap";
import "./buttons.css";

export default function ButtonPrimary(props:ButtonProps) {
    const { variant = 'hra-primary', ...restProps } = props;

    return (
        <Button  
        {...restProps}
        variant={variant}
        />
    );
}

export function ButtonLink(props:ButtonProps) {
    const { variant = 'hra-link', ...restProps } = props;

    return (
        <Button  
        {...restProps}
        variant={variant}
        />
    );
}
