import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import ButtonPrimary from "../../components/buttons/buttons";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import { useAuth } from "../../hooks/useAuth";
import dayjs from "dayjs";
import { BsClock } from "react-icons/bs";
import "./featureFund.css";
import { To, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../utils/common-utils";
import { ThemeProvider } from "styled-components";
import { FeatureFundText, FeatureFundTextTitle, FeaturFundLearn} from "../../components/styles/ThemeSwitching.styled";

export default function FeatureFund() {
  const [fundingProgram, setFundingProgram] = useState([]);
  const [features, setFeatures] = useState([]);

  const [inProgress, setInProgress] = useState({ eligible: false, all: false });


  const getAllFundingProgram = async () => {
    try {
      setInProgress((prev) => { return { ...prev, all: true } });
      await BaseAPI.getAllFundingProgram(async (result) => {        
        if (result.status === 200) {
            const filteredValue = result.data.filter(
                
                (item: any) => item.featureFunds === true
                
              );
          setFundingProgram(filteredValue);
        } else {
          console.log(result.message);
        }
        setInProgress((prev) => { return { ...prev, all: false } });
      });
    } catch (e) {
      console.log(e);
      setInProgress((prev) => { return { ...prev, all: false } });
    }
  };
  useEffect(() => {
    getAllFundingProgram();
  }, []);

  const getFeatures = async () => {
    try {
      await BaseAPI.getFeatures( (result: any) => {        
      if (result.status === 200) {
          setFeatures(result.data.f);

        } else {
          console.log(result.message);
        }

      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFeatures();
  }, []);


  const navigate = useNavigate();
  const handleClick = function (linkTo: To,resultId: number) {
    navigate(`${linkTo}/${resultId}`);
  }
  const program = fundingProgram.slice(0, 5);
  const hasFunds = program?.length > 0
  if(!hasFunds) {
    return (<></>)
  }


  return (

    <>
    {(true || features && features.length > 0 && features[0].enabled === 'true' )? (
    <Container className="funding-container-align">
      {program.length > 0 &&    (
        <div className="feature-fund-heading">
          <FeatureFundTextTitle className="fund-title-head">Featured Funds</FeatureFundTextTitle>
        </div>
      )}
  
      {program.map((result, index) => {
        return (
          <Col className="card-col" key={`cardc-${index}`}>
            <Card className="feture-fund-card fund-card-height">
              <Card.Body className="card-shadow">
                <p className="feat-fund-program">
                  {result?.fundingProgramName}
                </p>
                <FeatureFundText>{formatCurrency(result?.amount)} </FeatureFundText>
  
                <div className="text-center mt-4">
                  <FeaturFundLearn
                    className="feat-fund-learnmore"
                    onClick={() => handleClick("/programs", result.id)}
                  >
                    LEARN MORE
                  </FeaturFundLearn>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Container>
     ) : null }
  </>
  
      
  );
}
