import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import ButtonPrimary from "../../components/buttons/buttons";
import TitleCard from "../../components/title-card/titlecard";
import { ProjectContextType, ProjectContext } from "../project/project";
import "./pca-introduction.css";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import SignupOrLogin from "../../components/Modal/SignupOrLogin";
import ScrollToTop from "../../components/utilities/scroll";
import {  LinkColor, PcaContinue } from "../../components/styles/ThemeSwitching.styled";

const PCAIntroductionPage = () => {
  const params = useParams();
  const { user } = useAuth();
  const [modalType, setModalType] = useState('')
  const projectId = params && params.id ? +params.id : undefined;
  const projectContext: ProjectContextType = useContext(ProjectContext)
  const navigate = useNavigate();
  const onSubmit = async () => {
    try {
      if (projectContext.project.id === 'draft' || !projectContext.project.id) {
        await projectContext.saveProject((savedProjectId) => {
          navigate(`/projects/${savedProjectId}/pca/questions`)
        });
      } else {
        navigate(`/projects/${projectContext.project.id}/pca/questions`)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tenantId = process.env.REACT_APP_TENANT;

  
  return (
    <>

      <ScrollToTop />
      <div className="pca-introduction layout-container-content d-flex flex-fill flex-column">
        <div className="d-flex flex-fill">
          <main role="main" className="layout-main">
            <Container>
              <div className="pca-main d-flex flex-column">
                <TitleCard
                  title="Introduction"
                  description="ABOUT PROJECT READINESS ASSESSMENT (PRA)"
                  descriptionClass="pca-subtitle" />
                <Row className="justify-content-md-center">
                  <Col>

                  {tenantId === "1" && (
      <p className="pca-descriptive-text">
The IFN Beta’s Project Readiness Assessment (PRA) evaluates your project readiness and its competitiveness for federal funding applications. The PRA questionnaire helps evaluate how your project aligns with general principles and priorities of the federal government.
                      Based on your answers, the PRA will prepare your project’s readiness scores—broken down by each federal priority area as well as project components and their sub-criteria. The PRA also offers feedback on improving your project’s readiness, and you can contact <LinkColor href="mailto:infrastructure@hraadvisors.com">infrastructure@hraadvisors.com</LinkColor> if you need additional support in assessing your project or preparing for funding applications.
                                </p>
    )}

    {tenantId === "2" && (
      <p className="pca-descriptive-text">
La Liga’s Project Readiness Assessment (PRA) evaluates your project readiness and its competitiveness for federal funding applications. The PRA questionnaire helps evaluate how your project aligns with general principles and priorities of the federal government. Based on your answers, the PRA will prepare your project’s readiness scores—broken down by each federal priority area as well as project components and their sub-criteria. The PRA also offers feedback on improving your project’s readiness, and you can contact <LinkColor href="mailto: info@ligadeciudadespr.com "> info@ligadeciudadespr.com </LinkColor>if you need additional support in assessing your project or preparing for funding applications.</p>
    
    )}
                    <p >
                      
                    </p>
                  </Col>
                </Row>
                <Row className="funding-eligibility-container-btn-cot mt-auto-btn">
                  <Col className="pca-introduction-container-btn-back-align-top">
                    <a className="funding-eligibility-container-btn-back pca-intro-style" href="">
                      <ArrowLeft
                        className="arrow funding-eligibility-container-arrow-spacing"
                        size={25} />
                      Back
                    </a>
                  </Col>
                  <Col className="p-0">
                    {user ?
                      <PcaContinue
                        className="pca-introduction-container-btn-align btn-next"
                        onClick={onSubmit}
                      >
                        CONTINUE
                      </PcaContinue>
                      :
                      <ButtonPrimary
                        className="pca-introduction-container-btn-signup "
                        onClick={() => setModalType('signup')}
                      >
                        SIGN UP FOR EXTRA FEATURES
                      </ButtonPrimary>}
                  </Col>
                </Row>
              </div>
            </Container>
          </main>
        </div>
        <SignupOrLogin modalType={modalType} setModalType={setModalType}></SignupOrLogin>
      </div>

    </>
  );
};
export default PCAIntroductionPage;
