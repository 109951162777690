import { Container, Row, Col, Form } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import ButtonPrimary from "../../components/buttons/buttons";
import "./login.css";
import { BaseAPI } from "../../providers/base-api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { LoginFormData } from "../../models/auth";
import ModelWindow from "../../components/Modal/ModelWindow";
import ForgotForm from "../forgot-password/forgot-password";
import {  ButtonLogin} from "../../components/styles/ThemeSwitching.styled";

interface LoginPageProps {
  onLoginSuccessful: () => void;
  handleModelPopup: (event: any, type: string) => void;
  redirectTo?: string;
  closeModel?: any;
}

interface LoginFieldErrors {
  username?: string;
  password?: string;
  form?: string;
}

function LoginPage({ onLoginSuccessful, handleModelPopup, redirectTo, closeModel, }: LoginPageProps) {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false)
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState<LoginFieldErrors>({ username: '', password: '', form: '' })
  const [form, setForm] = useState<LoginFormData>({ username: '', password: '' });
  const { login, targetPath } = useAuth();

  const handleForgotPassword = async (value) => {
    //closeModel();
    setModalShow(true);
  };

  const onFieldChange = (field, value) => {
    setForm(prevState => ({ ...prevState, [field]: value }))

    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })

  }

  const validateForm = () => {
    const { username, password } = form
    const newErrors: LoginFieldErrors = {}
   if (!username || username === '') newErrors.username = 'This field is required'
    if (!password || password === '') newErrors.password = 'This field is required'

    return newErrors
  }

  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const redirectPath = redirectTo ? redirectTo : targetPath;

    const tenantId = process.env.REACT_APP_TENANT || 1
    const multiTenForm = {
      username: `${form.username}@@${tenantId}`,
      password: form.password
    }
    BaseAPI.login(multiTenForm, (res) => {
      
        if (res.status === 200) {
          login({
            email: form.username,
            token: res.data.access_token,
            tenantId: res.data.tenant_id,
            theme: res.data.theme,
            ff: res.data.ff,
            sp: res.data.sp
            
          });
          if (redirectPath) {
            navigate(redirectPath || "/projects", { replace: true });
          }
          onLoginSuccessful();
        } else {
          setValidated(true)
          setErrors({ form: 'Invalid Email / Password' })
          setHasError(true)
        }
      });

      // .catch(error => {

      //   setErrorMessage(error?.response?.data?.message)
      //   setErrors({ form: 'Invalid Email / Password' })
      //   setHasError(true)
      //   setValidated(true)
      // })
  }



  return (
    <>
      <Container className="login-container" >
        <Row>
          <Col lg={5}>
            <h6 className="log">Log In</h6>
            <h3 className="subtitle">Log in to your Infrastructure Funding Navigator profile to save your projects, retrive previously created projects, and access the Project Readiness Assessment.</h3>
          </Col>
          <Col lg={7}>
            <Form
              noValidate

              onSubmit={handleSubmit}
            >
              <Row className="login-field-row">
                <Col sm={12}>
                  <Form.Group>

                    <Form.Control
                      className={`form-control input-lg email ${hasError ? 'is-invalid' : ''}`}
                      type="text"
                      placeholder="Email address *"
                      value={form.username}
                      onChange={(e: any) => onFieldChange('username', e.target.value)}
                      required
                      isInvalid={!!errors.username}

                    />
                    <Form.Control.Feedback type='invalid' className="form-control-feedback">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                </Col>
                </Row>
                <Row className="login-field-row">
                <Col sm={12}>
                  <Form.Group>
                    <Form.Control
                      className={`form-control input-lg pass ${hasError ? 'is-invalid' : ''}`}
                      type="password"
                      placeholder="Password *"
                      value={form.password}
                      onChange={(e: any) => onFieldChange('password', e.target.value)}
                      required
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type='invalid' className="form-control-feedback">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {errors.form &&
                  <div className="h6-align">
                    <span >Invalid Email address/Password</span>
                  </div>
                }
              </Row>
              <Row>
                <Col>
                  <div className="d-flex justify-content-between align-item-center btn-alignment">
                    <a
                      href="#login"
                      className="signup"
                      onClick={(event) => handleModelPopup(event, "signup")}
                    >
                      SIGN UP
                    </a>
                    <div>
                <p className="forgot-password text-right forgot-password-login">
                  <a
                    className="forgot-password-login"
                    onClick={() => handleForgotPassword(true)}
                  >
                    Forgot Password?
                  </a>
                  <ModelWindow
                    size="xl"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    body={<ForgotForm />}
                  />
                </p>
              </div>
                    <ButtonLogin className="login log-in-modelpop-btn" type="submit" >
                      LOG IN &nbsp;
                      <ArrowRight className="arrow log-in-modelpop-arrow" size={26} />
                    </ButtonLogin>


                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginPage;
