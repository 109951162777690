import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModelWindow from "../Modal/ModelWindow";
import { useEffect, useState } from "react";
import ContactUsPage from "../../pages/contact-us/contact-us";
import "./header.css";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import SignupOrLogin from "../Modal/SignupOrLogin";
import LoginPage from "../../pages/login/login";
import SignUpPage from "../../pages/sign-up/sign-up";
import Translate from "../../pages/Translate/translate";

function Header() {
  const [modalShow, setModalShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showState, setShowState] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("Spanish");
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const { user, logout, targetPath, setTargetPath, loginPopupVisible, setLoginPopupVisible } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    setIsLoggedIn(false);
    setModalType(undefined);
    logout(targetPath || "/");
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const handleModelPopup = (event: any, name: string) => {
    event.preventDefault();
    setShowState(true);
    setModalType(name);
    if (modalType === "signup" && showState) {
      setLoginPopupVisible(false);
    }
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const onLoginSuccessful = () => {
    setIsLoggedIn(true);
    setLoginPopupVisible(false);
    closeLoginPopup();
    if (targetPath) {
      navigate(targetPath);
    }
  };

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const handleTranslateChanged = (isOpenTranslate) => {
    setShowHamburgerMenu(isOpenTranslate);
  };

  useEffect(() => {
    if (user) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      setIsLoggedIn(true);
      if (modalType === "login" && showState) {
        setShowState(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [user, modalType, showState]);

  const closeLoginModel = () => {
    setModalShow(false);
  };

  useEffect(() => {
    if (location.hash && location.hash === '#login' && modalType !== 'login') {
      if (location.state && location.state['from']) {
        setTargetPath(location.state['from']);
      }
      setModalType('login');
      navigate('/', { replace: true });
    }
  }, [location, modalType, setTargetPath, navigate]);

  return (
    <div className="App">
      <Navbar bg="light" expand="lg" sticky="top" className="hd-navs-bar-wd-align">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            {Number(process.env.REACT_APP_TENANT) === 1 ? (
                  <img
                    src="/IFN Logo-01-01.png"
                    // src="/logo-retina.png"
                    className="d-inline-block align-top"
                    alt="HR&amp;A"
                  />
                ) : (
                  <img
                    src="/LIGA Logo-01-01.png"
                    // src="/logo-retina.png"
                    className="d-inline-block align-bottom"
                    alt="HR&amp;A"
                  />
                )}
              
          </Navbar.Brand>
          <div className="beta">BETA</div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="ml-auto justify-content-end">
            <Nav className="ml-auto justify-content-end">
              <Nav.Link as={Link} to="/programs" onClick={() => handleMenuItemClick("programs")} className={`nav-link-default ${activeMenuItem === 'programs' ? 'nav-link-active' : ''}`}>
                FUNDING OPPORTUNITIES
              </Nav.Link>
              <Nav.Link as={Link} to="/projects" onClick={() => handleMenuItemClick("projects")} className={`nav-link-default ${activeMenuItem === 'projects' ? 'nav-link-active' : ''}`}>
                MY PROJECTS
              </Nav.Link>
              {!isLoggedIn ? (
                <Nav.Link onClick={(event) => setModalType("signup")} className={`nav-link-default ${activeMenuItem === 'login' ? 'nav-link-active' : ''}`}>
                  SIGN UP
                </Nav.Link>
              ) : null}
              <Nav.Link onClick={isLoggedIn ? () => handleLogout() : (event) => setModalType("login")} className={`nav-link-default ${activeMenuItem === 'login' ? 'nav-link-active' : ''}`}>
                {isLoggedIn ? "LOGOUT" : "LOG IN"}
              </Nav.Link>
              <Nav.Link onClick={() => setModalShow(true)} className={`nav-link-default ${activeMenuItem === 'login' ? 'nav-link-active' : ''}`}>
                CONTACT US
              </Nav.Link>
            </Nav>
              <Translate  rtl={false} setLanguage={handleLanguageChange} />
           </Navbar.Collapse>
        </Container>
      </Navbar>
      <ModelWindow
        size="xl"
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={<ContactUsPage onHide={() => setModalShow(false)} />}
      />
      <ModelWindow
        show={showState || loginPopupVisible}
        onHide={() => {
          setShowState(false);
          setLoginPopupVisible(false);
        }}
        body={
          loginPopupVisible ? (
            <LoginPage
              handleModelPopup={(event) => handleModelPopup(event, "login")}
              onLoginSuccessful={onLoginSuccessful}
            />
          ) : showState && modalType === "signup" ? (
            <SignUpPage handleModelPopup={(event) => handleModelPopup(event, "signup")} />
          ) : (
            <LoginPage
              handleModelPopup={(event) => handleModelPopup(event, "login")}
              onLoginSuccessful={onLoginSuccessful}
              closeModel={closeLoginModel}
            />
          )
        }
        contentClassName={
          modalType === "signup" ? "dialogSignUp" : "contentClassName"
        }
        dialogClassName="dialogClassName"
      />
      <SignupOrLogin modalType={modalType} setModalType={setModalType}></SignupOrLogin>
    </div>
  );
}

export default Header;

function closeLoginPopup() {
  throw new Error("Function not implemented.");
}
