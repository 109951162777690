interface TranslateOption {
    value: string;
    label: string;
  }
  
  export const TRANSLATE_OPTIONS: TranslateOption[] = [
    { value: 'es', label: 'Spanish' },
    { value: 'en', label: 'English' },
  
  ];
  
  export const RTL_COOKIES = ['/en/ar', '/en/ur'];
export const RTL_COUNTRIES = ['ar', 'ur'];

  export const googleTranslateScriptInit = (): void => {
    const script = document.getElementById('google_translate_script');
    if (!script) {
      const addScript = document.createElement("script");
      addScript.setAttribute('id', 'google_translate_script');
      addScript.setAttribute(
        "src",
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      (window as any).googleTranslateElementInit = googleTranslateElementInit;
    }
  };


  export const setCookie = (name: string, value: string, days?: number): void => {
    document.cookie = `${name}=${value};path=/`;
  };
  
  export const googleTranslateElementInit = (): void => {
    const allowedLanguages = TRANSLATE_OPTIONS.map((data) => data.value).join(',');
    setCookie('googtrans', '/auto/es');
    new (window as any).google.translate.TranslateElement(
      {
        pageLanguage: "auto",
        autoDisplay: false,
        offsite: true,
        includedLanguages: allowedLanguages,
        defaultLanguage: "es"
      },
      "google_translate_element"
    );
  };
  
  export const appendTranslateElement = (): boolean => {
    if (typeof document !== 'undefined') {
      const addElement = document.createElement("div");
      addElement.setAttribute('id', 'google_translate_element');
      document.body.appendChild(addElement);
      return true; 
    }
    return false;
  };
  
  export const customTranslate = (strList: string[], from: string, to: string): Promise<any> => {
    const params = `?client=gtx&sl=${from}&tl=${to}`;
    const url = `https://translate.googleapis.com/translate_a/t${params}`;
    let formBody = "";
    for (let i = 0; i < strList.length; i++) {
      formBody += `q=${encodeURIComponent(strList[i])}`;
      if (i !== strList.length - 1) {
        formBody += "&";
      }
    }
  
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((res: Response) => {
        return res.json();
      })
      .catch((e: Error) => {
        console.error("Custom Translate Error:", e);
        throw e;
      });
  };
  