import { useState } from "react";
import { Table } from "react-bootstrap";
import { sortData } from "../../utils/common-utils";
import "./datatable.css";

export default function DataTable(props: any) {
  const column: any = props?.columnData;
  const data: any = props?.dataResult;
  const arrangeValues = (oneData: any, index: number) => {
    let finalDataValue: any = [];
    oneData?.map((dataValue: any) => {
      if (
        column?.filter((item: any) => {
          return item.accessor === "serial";
        }).length > 0
      ) {
        finalDataValue.push(<td >{index + 1}</td>);
      }
      column?.map((columnValue: any, colIndex: number) => {
        let columnAccessor = columnValue?.accessor?.split(".");
        if (columnAccessor?.length > 1) {
          let valueObject = dataValue;
          columnAccessor?.forEach((objectAccessor: number) => {
            if(valueObject) {
                valueObject = valueObject[objectAccessor];
              }
            });
          valueObject = valueObject || '';
          finalDataValue.push(<td key={colIndex} style={columnValue?.style}>{valueObject}</td>);
        } else if (dataValue[columnValue?.accessor]) {
          finalDataValue.push(<td key={colIndex} style={columnValue?.style}>{dataValue[columnValue?.accessor] || ''}</td>);
        } else if (columnValue?.accessor === "button") {
          finalDataValue.push(<td key={colIndex} style={columnValue?.style}>{columnValue?.Cell(dataValue) || ''}</td>);
        }
      });
    });
    return finalDataValue;
  };
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState(false);
  
  const sortedData = sortData(data, sortField, sortOrder);
  return (
    <div className="card-body table-full-width table-responsive">
      <Table  hover>
      <thead className={`tb-head-tenant${process.env.REACT_APP_TENANT} === 1 ? "1" : "2"}`}>

        {/* <thead className='tb-head'> */}
          <tr>
            {column?.map((columnValue: any, index: number) => {
              return <th key={index} id={"th-"+index}>{columnValue?.header} </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((dataValue: any, index: number) => {
            return <tr key={index} className="case1 tbl-body-row">{arrangeValues?.([dataValue], index)}</tr>;
          })}
        </tbody>
      </Table>
    </div>
  );
}
