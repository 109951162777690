

export const green = {
  name: "green-theme",
  colors: {
    
    header: "#34986ad9",
      buttonContinue: "#fff",
      buttonBackground: "#D85A13",
      buttonBackgroundHover: "#B53D23",
      HomeBorderBoxColor: "rgb(23,63,64)",
      background: "rgb(35 136 139)",
      signupColor: "rgb(171 205 192)",
      projectHeadBar: "rgb(69,106,99)",
    projectSideBar:"hsl(195deg 18.92% 49.66%)",
    buttonBackgroundHover: "#B53D23",
 
    programHead: "#f5f0e0",
    featureFund: "#548075",
    cardBorderClr:"#86a096",
    eligibleHead:"#362727",
    cardBorderClr:"#86a096",
    button:"#D85A13",  
    projectButtonColor: "rgb(216,90,19)",
    buttonLogin:"#D85A13",  
    buttonReadiness:"#D85A13",  
    buttonPrint:'#D85A13',
    borderTop: "9px solid",
    footer: "#E1E1D5",
    text: "hsl(180deg 7.81% 51.32%)",
    quoteBgc: "#004d4d",
    quoteTitle: "#86a096",
    quoteBody: "hsl(0, 0%, 38%)",
    quoteBorder: "hsl(0, 0%, 87%)",
    border: "rgb(69,106,99)",
    height: "40px",
   ProjectsTilte:"#456A63",
  ProjectSideBar:"#456A63",
    footerColor: "#E1E1D5",
    questionCountColor: "#86a096",
    LinkColor: "#0d6efd",
   
  

  DocumentLinkColor:"#D85A13",
LinkIcon:"#D85A13"
  },
};

export const blue = {
  name: "blue-theme",
  colors: {
   header: "rgb(91, 158, 244)",
   background: "rgb(91,158,244)",
      // projectHeadBar: "rgb(0,62,122)",
      buttonContinue: "#000",
      HomeBorderBoxColor: "rgb(91,158,244)",
      buttonBackgroundHover: "#003E7A",
      buttonBackground: "rgb(0,62,122)",
      projectButtonColor: "#5b9ef3",
      projectHeadBar: "rgb(0,62,122)",
      buttonBackgroundHover: "#003E7A",

    footer:  "#f8f9fa",
    programHead: "#f0efef",
    eligibleHead:"#003e7a",
    projectSideBar:"#f0efef",
    button:"#5b9ef3",
    featureFund: "#003e7a",
    signupColor: "#fff",
    buttonLogin:"#5b9ef3",
    buttonReadiness:"#5b9ef3",
    buttonPrint:"#5b9ef3",
    cardBorderClr:"#003e7a",
    text: "#888",
    quoteBgc: "#6e86a5",
    quoteTitle: "#003e7a",
    border: "rgb(0,62,122)",
    quoteBody: "hsl(320, 20%, 28%)",
    ProjectsTilte:"#003e7a",
    ProjectSideBar:"#003e7a",
  
    footerColor: "#f8f9fa",
    questionCountColor: "#5b9ef4",
    LinkColor: "rgb(0,62,122)",
    DocumentLinkColor:"#0d6efd",
    LinkIcon: "#0d6efd"
  },
};

