import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonPrimary from "../../components/buttons/buttons";
import ScrollToTop from "../../components/utilities/scroll";
import TitleCard from "../../components/title-card/titlecard";
import {
  ProjectAnswer,
  Question,
  QuestionAnswerChoice,
} from "../../models/project";
import { BaseAPI } from "../../providers/base-api";
import { ProjectContextType, ProjectContext } from "../project/project";
import "./pca-questions.css";
import {  BackButton, ButtonContinue, RadioButtonColor } from "../../components/styles/ThemeSwitching.styled";


export default function PcaQuestions() {
  const params = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const criteriaIdParam = +searchParams.get("cid");
  const pComponentIdParam = +searchParams.get("pcid");
  const fromParam = searchParams.get("from");
  const pQuestionIdParam = fromParam && fromParam === 'qn' ? +searchParams.get("qid") : undefined;
  const projectId = params && params.id ? +params.id : undefined;
  const projectContext: ProjectContextType = useContext(ProjectContext);
  const [pComponentId, setProjectComponentId] = useState(undefined);
  const [criteriaId, setCriteriaId] = useState(undefined);
  const [questionId, setQuestionId] = useState(undefined);

  const [currentQuestion, setCurrentQuestion] = useState<Question>();

  const defaultAnswer: ProjectAnswer = useMemo<ProjectAnswer>(
    () => ({
      projectId: projectId,
      questionId: undefined,
      answerChoiceId: undefined,
      answerInput: "",
    }),
    [projectId]
  );
  const [answer, setAnswer] = useState<ProjectAnswer>(defaultAnswer);
  const pcaData = projectContext.projectPCA;
  const handleQuestionSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    submitAnswer().then((result) => {
      handleNextQuestion();
    });
  };
  const handleNextQuestion = (options?: { moveBackwards: boolean }) => {
    const { moveBackwards } = options || { moveBackwards: false };
    const nextQuestionIndex = Math.max(
      0,
      pcaData?.questionList.findIndex(
        (question) => question.id === currentQuestion.id
      ) + (moveBackwards ? -1 : 1)
    );
    if (pcaData.questionList.length > nextQuestionIndex) {
      navigateToQuestion(nextQuestionIndex);
      return;
    } else {
      navigate(`/projects/${projectId}/summary`);
    }
  };
  const navigateToQuestion = useCallback(
    (questionIndex) => {
      const nextQuestion = pcaData?.questionList[questionIndex];
      const nextCriteriaId = nextQuestion.criteria.id;
      const nextpCompId = nextQuestion.projectComponentId;
      navigate(
        `/projects/${projectId}/pca/questions?pcid=${nextpCompId}&cid=${nextCriteriaId}&qid=${nextQuestion.id}&from=qn`
      );
    },
    [navigate, pcaData?.questionList, projectId]
  );

  const handleSkip = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleNextQuestion();
  };
  const handleprevious = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleNextQuestion({ moveBackwards: true });
  };

  useEffect(() => {
    setProjectComponentId(pComponentIdParam);
    setCriteriaId(criteriaIdParam);
    setQuestionId(pQuestionIdParam);
  }, [criteriaIdParam, pComponentIdParam, pQuestionIdParam, fromParam]);

  useEffect(() => {
    if (!pcaData || !projectId) {
      return;
    }

    let question: Question;
    if (pcaData?.questionList?.length > 0) {
      let matchingQuestions: Question[];
      if (questionId) {
        matchingQuestions = pcaData?.questionList.filter(
          (question) => question.id === questionId
        );
      } else if (pComponentId && criteriaId) {
        matchingQuestions = pcaData?.questionList.filter(
          (question) => question.criteria.id === criteriaId
        );
        if (matchingQuestions?.length > 0) {
          question = matchingQuestions[0];
          const questionIndex = pcaData?.questionList.findIndex(
            (questionItem) => questionItem.id === question.id
          )
          navigateToQuestion(questionIndex)
        }
      }
      if (matchingQuestions?.length > 0) {
        question = matchingQuestions[0];
      }
      if (!question) {
        navigateToQuestion(0);
      }
    }
    if (question) {
      setAnswer(
        question.projectAnswer || {
          projectId: projectId,
          questionId: question.id,
          answerChoiceId: undefined,
          answerInput: "",
        }
      );
    }

    setCurrentQuestion(question);
  }, [
    pcaData,
    pComponentId,
    criteriaId,
    questionId,
    projectId,
    navigate,
    navigateToQuestion,
  ]);

  const handleFieldChange = (fieldName, value, index: number) => {
    setAnswer((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const submitAnswer = async () => {
    let payLoad = {
      questionId: answer?.questionId,
      answerChoiceId: answer?.answerChoiceId,
      answerInput: answer?.answerInput,
      projectId,
    };

    await BaseAPI.saveQuestions(payLoad, (result) => {
      if (result.status === 201) {
        projectContext.setPcaTs(new Date().getMilliseconds());
      } else {
        console.log(result.message);
      }
    });
  };


  return (
    <>

      <ScrollToTop />
      <div className="col-9 question-container layout-container-content d-flex flex-fill flex-column" >
        <div className="d-flex flex-fill">
          <main role="main" className="layout-main flex-fill">
            <Container>
              {currentQuestion &&
                (!answer ||
                  !answer.questionId ||
                  answer.questionId === currentQuestion.id) && (
                  <div className="question-main">
                    <TitleCard title={currentQuestion.subCriteria?.name} />

                    <div>
                      {currentQuestion.priorityArea?.name && (
                        <p className="cate-btn">
                          {currentQuestion.priorityArea?.name}
                        </p>
                      )}
                    </div>

                    <br />
                    <h5 className="question-title">
                      {currentQuestion.questionShort}
                    </h5>
                    <div>
                      <p className="description-title">
                        {currentQuestion.description}
                      </p>
                      <br />
                    </div>

                    <Form
                      className="checkbox answer-choices"
                      onSubmit={handleQuestionSubmit}
                    >
                      <Form.Group controlId="formAnswerChoiceGroup">
                        {currentQuestion?.choices?.map(
                          (choice: QuestionAnswerChoice, choiceIndex: number) => {
                            return (
                              <div key={`cgc-${choiceIndex}`}>
                                <Form.Check key={`cg-${choiceIndex}`}>
                                  <div className="pca-questions-check-alignment">
                                    <div>
                                      <RadioButtonColor
                                        key={`ci-${choiceIndex}`}
                                        name="choice"
                                        type="radio"
                                        onChange={(event) =>
                                          handleFieldChange(
                                            "answerChoiceId",
                                            choice.id,
                                            choiceIndex
                                          )
                                        }
                                        checked={
                                          answer.answerChoiceId === choice.id
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div>
                                        <Form.Check.Label
                                          key={`cl-${choiceIndex}`}
                                          htmlFor="choice"
                                          onClick={(event) =>
                                            handleFieldChange(
                                              "answerChoiceId",
                                              choice.id,
                                              choiceIndex
                                            )
                                          }
                                        >
                                          {choice.choiceText}
                                        </Form.Check.Label>
                                      </div>
                                      {answer &&
                                        answer.answerChoiceId === choice.id &&
                                        choice?.showInput && (
                                          <div key={`cifc-${choiceIndex}`}>
                                            <Form.Control
                                              className="checkbox-text"
                                              placeholder="Enter input value"
                                              type="text"
                                              name="answerInput"
                                              value={answer.answerInput}
                                              key={`cif-${choiceIndex}`}
                                              onChange={(e: any) =>
                                                handleFieldChange(
                                                  "answerInput",
                                                  e.target.value,
                                                  choiceIndex
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </Form.Check>
                              </div>
                            );
                          }
                        )}
                      </Form.Group>
                      <Row className="pca-question-btn-ro-hi">
                        <Col xl={4} sm={4}>
                          <div className="pca-question-bck-skip-btn">
                            <BackButton
                              className="pca-question-btn-back"
                              href="#"
                              onClick={handleprevious}
                            >
                              <ArrowLeft
                                className="arrow funding-eligibility-container-arrow-spacing"
                                size={25}
                              />
                              Back
                            </BackButton>
                          </div>
                        </Col>
                        <Col xl={5} sm={5}>
                          <div className="pca-question-bck-skip-btn">
                            <BackButton className="skip-btn" href="" onClick={handleSkip}>
                              SKIP
                            </BackButton>
                          </div>
                        </Col>
                        <Col
                          xl={3}
                          sm={3}
                          className="pca-question-skip-continue1"
                        >
                          <div>
                            <ButtonContinue className="pca-add-btn" type="submit">
                              CONTINUE
                            </ButtonContinue>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
            </Container>
          </main>
        </div>
      </div>
    </>
  );
}
